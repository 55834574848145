<script setup lang="ts">
    import { useListen, useEvent } from '@/composables/useEventBus';
    const { $theme } = useNuxtApp();
    const $emit = defineEmits(["before-open", "opened", "before-close", "closed"]);
    const { getModalZIndex } = useModalsStore();

    const props = defineProps({
        name: {
            required: true,
            type: String,
        },
        modalStyle: {
            required: false,
            type: String,
            default: 'centered',
        },
        width: {
            required: false,
            default() {
                return 600;
            },
        },

        showCloseIcon: {
            required: false,
            type: Boolean,
            default() {
                return true;
            },
        },

        closeIconTopPosition: {
            required: false,
            type: String,
        },

        contentClasses: {
            required: false,
            type: String,
        },
        transition: {
            required: false,
            type: String,
            default: 'vm-transition--modal',
        },
        slotContainerClasses: {
            required: false,
            type: String,
            default: () => 'w-full h-full',
        },
        scrollable: {
            required: false,
            type: Boolean,
            default: false,
        },
    });

    const modelContentClasses = computed(() => props.contentClasses || $theme(`modal.${props.modalStyle}.content`));

    const modalContainer = ref(null);
    const opened = ref(false);

    const open = () => {
        $emit('before-open', props.name);
        opened.value = true;
        $emit('opened', props.name);
        nextTick(() => {
            modalContainer.value?.focus();
        });
    };

    const close = () => {
        $emit('before-close', props.name);
        opened.value = false;
        $emit('closed', props.name);
    };

    useListen('modal:open', (name: string) => {
        if (name === props.name) {
            open();
        }
    });

    useListen('modal:close', (name: string) => {
        if (name === props.name && opened.value) {
            close();
        }
    });

    defineExpose({
        open,
        close,
        opened,
    });
</script>

<template>
    <transition
        :enter-active-class="$theme(`modal.${modalStyle}.transition.enter-active-class`)"
        :enter-from-class="$theme(`modal.${modalStyle}.transition.enter-from-class`)"
        :enter-to-class="$theme(`modal.${modalStyle}.transition.enter-to-class`)"
        :leave-active-class="$theme(`modal.${modalStyle}.transition.leave-active-class`)"
        :leave-from-class="$theme(`modal.${modalStyle}.transition.leave-from-class`)"
        :leave-to-class="$theme(`modal.${modalStyle}.transition.leave-to-class`)"
    >
        <div
            v-if="opened"
            class="pointer-events-none"
            :class="$theme(`modal.${modalStyle}.container.outer`)"
            :data-modal="name"
            :style="`z-index: ${getModalZIndex(name)};`"
        >
            <div
                ref="modalContainer"
                :class="$theme(`modal.${modalStyle}.container.inner`)"
                class="pointer-events-auto"
                :style="modalStyle == 'centered' ? `width: ${props.width}px` : ''"
                :tabindex="opened ? 100 : -1"
            >
                <div :class="modelContentClasses">
                    <div
                        v-if="showCloseIcon"
                        :class="closeIconTopPosition ? `absolute ${closeIconTopPosition} -top- right-0 z-10 pt-6 pr-4 flex items-center justify-center` : $theme('modal.closeIcon.container')"
                    >
                        <button
                            :class="$theme('modal.closeIcon.button')"
                            @click="useEvent('modal:close', name)"
                        >
                            <i class="fa-regular fa-xmark"></i>
                        </button>
                    </div>
                    <div :class="[slotContainerClasses, scrollable ? 'overflow-y-auto nice-scrollbar' : '', modalStyle == 'centered' ? 'centered-modal-max-height' : '']">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<style>
    .centered-modal-max-height {
        max-height: calc(100vh - 40px);
    }
</style>
