<script setup lang="ts">
    import Invoice from '~/app/invoicing/models/Invoice';
    import useSendPaymentReminderModal from '~/composables/useSendPaymentReminderModal';

    const emit = defineEmits(['action']);
    const { t } = useTranslation();

    const props = defineProps({
  invoice: null,
  canSee: { type: Boolean, default: false },
  onAction: { type: Function, default: null },
  submit: { type: Function, default: null },
  preview: { type: Function, default: null },
  isDirty: { type: Boolean, default: false },
  displayShortcuts: { type: Boolean, default: false }
});

    const { unarchive, archive, destroy, printPdf, downloadPdf, downloadPricelessPdf, downloadSignedPdf, unlinkWorksite } = useInvoiceActions(props.invoice, props.onAction);
    const { openModal } = useSendEmailModal();
    const paymentReminderModal = useSendPaymentReminderModal();
    const smsModal = useSendSMSModal();

    const submitFirst = async (callback: () => void) => {
        if (!props.isDirty) {
            await callback();
            return;
        }

        if (props.submit && !window.confirm(t('invoicing.invoice_form.confirm_submit_before_continuing'))) {
            return;
        }

        if (props.submit) {
            const success = await props.submit(callback);
            if (success == false) {
                return;
            }
        }
        callback();
    };
    const submitFirstAsync = async (callback: () => Promise<void>) => {
        if (!props.isDirty) {
            await callback();
            return;
        }

        if (props.submit && !window.confirm(t('invoicing.invoice_form.confirm_submit_before_continuing'))) {
            return;
        }

        if (props.submit) {
            const success = await props.submit(callback);
            if (success == false) {
                return;
            }
        }
        await callback();
    };

    const hasWorksiteProgressBillings = computed(() => props.invoice?.worksiteProgressBillings?.items?.length > 0);
</script>

<template>
    <div :class="$theme('submenu.items_container')">
        <div :class="$theme('submenu.items')">
            <a
                v-if="canSee && invoice"
                href="#"
                :class="$theme('submenu.item')"
                @click.prevent="
                    invoice.preview();
                    onAction();
                "
            >
                <i
                    class="fa-regular fa-eye"
                    :class="$theme('submenu.item_icon')"
                ></i>
                <span>
                    {{ $t('actions.see') }}
                </span>
            </a>
            <LoadingButton
                v-if="preview"
                :class-name="$theme('submenu.item', 'relative')"
                prevent-default
                @clicked="preview"
            >
                <i
                    class="fa-regular fa-eye"
                    :class="$theme('submenu.item_icon')"
                ></i>
                <div class="flex items-center justify-between w-full">
                    <span>
                        {{ $t('actions.preview') }}
                    </span>
                    <span class="text-xs text-gray-400">⌘P</span>
                </div>
            </LoadingButton>
            <LoadingButton
                v-if="invoice?.signedPdfUrl"
                :class-name="$theme('submenu.item', 'relative')"
                prevent-default
                @clicked="(payload) => downloadSignedPdf(payload)"
            >
                <i
                    class="fa-regular fa-signature"
                    :class="$theme('submenu.item_icon')"
                ></i>
                <span>{{ $t('actions.download_signed_pdf') }}</span>
            </LoadingButton>
            <LoadingButton
                v-if="invoice"
                :class-name="$theme('submenu.item', 'relative')"
                prevent-default
                @clicked="(payload) => submitFirstAsync(() => downloadPdf(payload))"
            >
                <i
                    class="fa-regular fa-download"
                    :class="$theme('submenu.item_icon')"
                ></i>
                <span>
                    {{ $t('actions.download') }}
                </span>
            </LoadingButton>
            <LoadingButton
                v-if="invoice"
                :class-name="$theme('submenu.item', 'relative')"
                prevent-default
                @clicked="(payload) => submitFirstAsync(() => printPdf(payload))"
            >
                <i
                    class="fa-regular fa-print"
                    :class="$theme('submenu.item_icon')"
                ></i>
                <span>
                    {{ $t('actions.print') }}
                </span>
            </LoadingButton>
            <LoadingButton
                v-if="invoice?.documentType == 'quote'"
                :class-name="$theme('submenu.item', 'relative')"
                prevent-default
                @clicked="(payload) => submitFirstAsync(() => downloadPricelessPdf(payload))"
            >
                <i
                    class="fa-regular fa-file-check"
                    :class="$theme('submenu.item_icon')"
                ></i>
                <span>
                    {{ $t('actions.download_information_sheet') }}
                </span>
            </LoadingButton>
        </div>
        <div
            v-if="invoice"
            :class="$theme('submenu.items')"
        >
            <a
                v-if="invoice.canChangeStatus()"
                href="#"
                :class="$theme('submenu.item')"
                @click.prevent="
                    submitFirst(() => useEvent('invoicing:invoice:change-status:open', { invoice }));
                    onAction();
                "
            >
                <i
                    class="fa-regular fa-thumbs-up"
                    :class="$theme('submenu.item_icon')"
                ></i>
                <span>
                    {{ $t('actions.change_status') }}
                </span>
            </a>
            <a
                v-if="invoice.status == 'draft'"
                href="#"
                :class="$theme('submenu.item')"
                @click.prevent="
                    submitFirst(() => {
                        openModal(invoice);
                    });
                    onAction();
                "
            >
                <i
                    class="fa-regular fa-circle-check"
                    :class="$theme('submenu.item_icon')"
                ></i>
                <span>
                    {{ $t('actions.mark_as_sent') }}
                </span>
            </a>
            <a
                href="#"
                :class="$theme('submenu.item')"
                @click.prevent="
                    submitFirst(() => {
                        openModal(invoice);
                    });
                    onAction();
                "
            >
                <i
                    class="fa-regular fa-paper-plane"
                    :class="$theme('submenu.item_icon')"
                ></i>
                <span>
                    {{ $t('actions.send_by_email') }}
                </span>
            </a>
            <a
                v-if="invoice.number"
                href="#"
                :class="$theme('submenu.item')"
                @click.prevent="
                    submitFirst(() => smsModal.openModal(invoice));
                    onAction();
                "
            >
                <i
                    class="fa-regular fa-message"
                    :class="$theme('submenu.item_icon')"
                ></i>
                <span>
                    {{ $t('actions.send_by_sms') }}
                </span>
            </a>
            <template v-if="invoice.documentType == 'invoice' && invoice.status !== 'draft'">
                <a
                    href="#"
                    :class="$theme('submenu.item')"
                    @click.prevent="
                        submitFirst(() => paymentReminderModal.openModal(invoice));
                        onAction();
                    "
                >
                    <i
                        class="fa-regular fa-calendar"
                        :class="$theme('submenu.item_icon')"
                    ></i>
                    <span>
                        {{ $t('actions.send_payment_reminder') }}
                    </span>
                </a>
            </template>
            <template v-if="invoice.documentType == 'quote' && invoice.status !== 'draft'">
                <LoadingButton
                    :class-name="$theme('submenu.item', 'relative')"
                    prevent-default
                    @clicked="
                        submitFirst(() => useEvent('invoicing:invoice:deposit-invoice:open', { invoice }));
                        onAction();
                    "
                >
                    <i
                        class="fa-regular fa-file-circle-plus"
                        :class="$theme('submenu.item_icon')"
                    ></i>
                    <span>
                        {{ $t('actions.create_deposit_invoice') }}
                    </span>
                </LoadingButton>
                <LoadingButton
                    :class-name="$theme('submenu.item', 'relative')"
                    prevent-default
                    @clicked="
                        submitFirst(() => useEvent('invoicing:invoice:final-invoice:open', { invoice }));
                        onAction();
                    "
                >
                    <i
                        class="fa-regular fa-file-circle-check"
                        :class="$theme('submenu.item_icon')"
                    ></i>
                    <span>
                        {{ $t('actions.create_final_invoice') }}
                    </span>
                </LoadingButton>
            </template>
            <template v-if="invoice.canRecordPayment()">
                <LoadingButton
                    :class-name="$theme('submenu.item', 'relative')"
                    prevent-default
                    @clicked="
                        submitFirst(() =>
                            useEvent('invoicing:invoice-payment:form-modal:open', {
                                fields: { invoice },
                            })
                        );
                        onAction();
                    "
                >
                    <i
                        class="fa-regular fa-euro"
                        :class="$theme('submenu.item_icon')"
                    ></i>
                    <span>
                        {{ $t('actions.record_payment') }}
                    </span>
                </LoadingButton>
            </template>
        </div>
        <div :class="$theme('submenu.items')">
            <LoadingButton
                v-if="submit"
                :class-name="$theme('submenu.item')"
                prevent-default
                @clicked="
                    submit();
                    onAction();
                "
            >
                <i
                    class="fa-regular fa-save"
                    :class="$theme('submenu.item_icon')"
                ></i>
                <div class="flex items-center justify-between w-full">
                    <span>
                        {{ $t('actions.save') }}
                    </span>
                    <span class="text-xs text-gray-400">⌘S</span>
                </div>
            </LoadingButton>
            <a
                v-if="invoice"
                href="#"
                :class="$theme('submenu.item')"
                @click.prevent="
                    submitFirst(() => useEvent('invoicing:invoice:replicate:open', { invoice }));
                    onAction();
                "
            >
                <i
                    class="fa-regular fa-copy"
                    :class="$theme('submenu.item_icon')"
                ></i>
                <span>
                    {{ $t('actions.duplicate') }}
                </span>
            </a>
            <a
                v-if="!submit && invoice"
                href="#"
                :class="$theme('submenu.item')"
                @click.prevent="
                    invoice.edit();
                    onAction();
                "
            >
                <i
                    class="fa-regular fa-pencil"
                    :class="$theme('submenu.item_icon')"
                ></i>
                <span>{{ $t('actions.edit') }}</span>
            </a>
            <LoadingButton
                v-if="invoice && !invoice.archivedAt"
                :class-name="$theme('submenu.item', 'relative')"
                @clicked="archive"
            >
                <i
                    class="fa-regular fa-box-archive"
                    :class="$theme('submenu.item_icon')"
                ></i>
                <span>{{ $t('actions.archive') }}</span>
            </LoadingButton>
            <LoadingButton
                v-if="invoice?.archivedAt"
                :class-name="$theme('submenu.item', 'relative')"
                @clicked="unarchive"
            >
                <i
                    class="fa-regular fa-inbox"
                    :class="$theme('submenu.item_icon')"
                ></i>
                <span>{{ $t('actions.unarchive') }}</span>
            </LoadingButton>
            <LoadingButton
                v-if="invoice?.canBeDeleted && !hasWorksiteProgressBillings"
                :class-name="$theme('submenu.item', 'relative')"
                @clicked="
                    useEvent('invoicing:invoice:delete-alert:open', { invoice });
                    onAction();
                "
            >
                <i
                    class="fa-regular fa-trash"
                    :class="$theme('submenu.item_icon')"
                ></i>
                <div class="flex items-center justify-between w-full">
                    <span>
                        {{ $t('actions.delete') }}
                    </span>
                    <!-- <span class="text-xs text-gray-400">⌘⌫</span> -->
                </div>
            </LoadingButton>
            <a
                v-if="invoice?.worksite"
                href="#"
                :class="$theme('submenu.item')"
                @click.prevent="
                    invoice.worksite.open();
                    onAction();
                "
            >
                <i
                    class="fa-regular fa-hard-hat"
                    :class="$theme('submenu.item_icon')"
                ></i>
                <span>{{ $t('actions.open_worksite') }}</span>
            </a>
            <LoadingButton
                v-if="invoice?.worksite && !invoice?.hasWorksiteProgressBillings()"
                href="#"
                :class-name="$theme('submenu.item', 'relative')"
                @clicked="unlinkWorksite"
            >
                <i
                    class="fa-regular fa-link-slash"
                    :class="$theme('submenu.item_icon')"
                ></i>
                <span>{{ $t('actions.unlink_worksite') }}</span>
            </LoadingButton>
            <a
                v-if="!submit && !invoice?.worksite"
                href="#"
                :class="$theme('submenu.item')"
                @click.prevent="
                    submitFirst(() => useEvent('invoicing:invoice:link-to-worksite:open', { invoice }));
                    onAction();
                "
            >
                <i
                    class="fa-regular fa-hard-hat"
                    :class="$theme('submenu.item_icon')"
                ></i>
                <span>{{ $t('actions.link_to_worksite') }}</span>
            </a>
        </div>
    </div>
</template>
