import Invoice from '~/app/invoicing/models/Invoice';

const useSendSMSModal = () => {
    const openModal = (invoice?: Invoice) => {
        const eventName = invoice?.contact ? 'invoicing:invoice:send-sms:open' : 'invoicing:invoice:select-contact:open';
        useEvent(eventName, { invoice });
    };

    return {
        openModal,
    };
};

export default useSendSMSModal;
